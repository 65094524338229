@import '../../../default';

.page-user-request{

    .main-frame{
        width: 100%;
        max-width: $max-width-app;
        margin: 0 auto;
        box-sizing: border-box;
        padding: 20px;

        .card{
            border-radius: 5px;
            border: 1px solid #ccc;
            box-sizing: border-box;
            padding: 10px 15px;
            margin: 20px auto;

            .avatar{
                overflow: hidden;
                border-radius: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                max-width: 100px;
                text-align: center;
                margin: 0 auto;
            }

            .status{
               display: inline-flex;
               align-items: center;

               &::before{
                   width: 10px;
                   height: 10px;
                   box-sizing: border-box;
                   content: '';
                   display: inline-block;
                   border-radius: 50%;
                   margin: 2.5px 5px;
               }

               &.on{
                   &::before{
                       background-color: var(--green-400);
                       animation: stauson 1s linear infinite;
                   }
               }

               &.close{
                &::before{
                    background-color: var(--green-400);
                }
                
                }

                &.cancel{
                    &::before{
                        background-color: #F00;
                        border: 0;
                    }
                    
                    }

               @keyframes stauson {
                    0%,100%{
                        box-shadow: 0 0 0 var(--green-700);
                    }
                    50%{
                        box-shadow: 0 0 5px var(--green-700),
                                    0 0 7px var(--green-700);
                    }
               }
            }


        }

        label{
            font-size: 0.8em;
        }

        .area-button{
            text-align: right;

            .p-button{
                width: 100%;
            }

            @media screen and (max-width: 500px) {
                text-align: center;
            }
        }
    }
}