@import '../../default';

.page-cart-list{

    .main-frame{
        width: 100%;
        max-width: $max-width-app;
        margin: 0 auto;
        box-sizing: border-box;
        padding: 20px;

        .p-placeholder{
            font-size: 0.8em;
        }
    }
}