@import '../../default';

.page-store{
    width: 100%;

    header{
        position: relative;
        background-image: url(../../../image/bg_header.jpg);
        background-size: cover;
        background-position: center center;
        background-attachment: fixed;
        height: 30vh;

        &::before{
            content: '';
            background-image: linear-gradient(45deg,rgba(61,61,61,.8),rgba(128, 127, 127, 0.8));
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            display: block;
            position: absolute;
            z-index: 2;
        }

        .cover{
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            display: block;
            position: absolute;
            z-index: 1;
            background-size: 100% auto;
            background-position: center center;
        }

        .client-info{
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            display: flex;
            position: absolute;
            z-index: 3;
            align-items: center;
            justify-content: center;

            .data-client{


                @media screen and (max-width: 500px) {
                    display: grid;
                    grid-template-columns: 100px 1fr;
                    column-gap: 10px;
                    align-items: center;
                    width: 90%;
                }

                .avatar{
                    width: 120px;
                    height: 120px;
                    display:  flex;
                    align-items: center;
                    justify-content: center;
                    margin: 0 auto;
                    background-color: #fff;
                    border: 5px solid #fff;

                    > .image-loader{
                        width: 100%;
                        height: 100%;
                    }

                    @media screen and (max-width: 500px) {
                        width: 100px;
                        height: 100px;
                    }

                }

                .text{
                    text-align: center;
                    color: #FFF;

                    @media screen and (max-width: 500px) {
                        text-align: left;
                    }
                }
            }
        }


        
    }

    .area-info{
        box-sizing: border-box;
        padding: 20px;
        width: 100%;
        background-color: #eee;
        display: grid;
        grid-template-columns: repeat(4,auto);
        grid-column-gap: 10px;
        -webkit-column-gap: 10px;
        column-gap: 10px;
        grid-row-gap: 10px;
        row-gap: 10px;
        justify-content: center;

        @media screen and (max-width: 1024px) {
            grid-template-columns: repeat(2,1fr);
        }

        @media screen and (max-width: 500px) {
            grid-template-columns: 1fr;
        }


        .box{
            display: grid;
            grid-template-columns: 40px auto;
            width: 100%;
            grid-row-gap: 20px;
            row-gap: 20px;
            align-items: center;

            .icon-image{
                width: 36px;
                max-width: 36px;
                height: 36px;
                max-height: 36px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;

                img{
                    max-width: 100%;
                    display: block;
                }
            }

            .text{
                font-size: .8em;
                box-sizing: border-box;
                padding: .5rem 1rem;
            }
        }
    }

    > nav{
        width: 100%;
        max-width: $max-width-app;
        margin: 10px auto;

        .p-menuitem-text{
            white-space: nowrap;

            @media screen and (max-width: 500px) {
                font-size: 0.8em;
            }
        }

        &.fixed{
            

            .p-tabmenu{
                width: 100%;
                max-width: $max-width-app;
                position: fixed;
                top: 74px;
                left: 50%;
                z-index: 10;
                transform: translateX(-50%);
            }
        }
    }

    > section{
        width: 100%;
        max-width: $max-width-app;
        margin: 0 auto;

        .area-products{
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            row-gap: 15px;
            column-gap: 15px;
            box-sizing: border-box;
            padding: 5px;

            @media screen and (max-width: 500px){
                grid-template-columns: 1fr;
                padding: 10px;
            }
        }
    }

    .area-promotion{
        width: 100%;
        max-width: $max-width-app;
        margin: 0 auto;
    }

    footer{
        width: 100%;
        height: auto;
        background-color: #3a0303;
        color: #fff;
        box-sizing: border-box;
        padding: 20px;
        margin-top: 25px;

        @media screen and (max-width: 500px) {
            font-size: 0.8em;
        }

        .p-grid{
            max-width: $max-width-app;
            margin: 0 auto;
        }

        .slogan{
            font-size: .8em;
        }

        .social-media{
            display: flex;
            box-sizing: border-box;
            padding: 20px 0;

            > i{
                margin: 5px;
                cursor: pointer;
            }
        }
    }
}